<template>
  <div class="userCheck">
    <div class="header">
      <span class="text">请选择审核状态：</span>
      <el-select 
        v-model="selectStatus" 
        class="m-2" 
        placeholder="请选择审核状态" 
        size="large"
        @change="handleSelectStatus"
      >
        <el-option
          v-for="item in statusArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
      
    <template>
      <el-table
        :data="userList"
        style="width: 100%;"
        :header-cell-style="headClass"
      >
        <el-table-column prop="dynamicContent" label="文案"></el-table-column>
        <el-table-column label="图片">
          <template 
            slot-scope="scope" 
            style="display: flex; flex-wrap: wrap;justify-content: start;"
          >
            <template v-for="(item, index) in scope.row.materials">
              <el-image :src="item.displayInformationUrl" style="width: 50px;height: 50px;" :preview-src-list="[item.displayInformationUrl]"></el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="wxUserId" label="用户id"></el-table-column>
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
        <el-table-column width="200px" label="状态" align="right">
          <template slot-scope="scope">
            <span v-if="scope.row.isPass === 0">待审核</span>
            <span v-if="scope.row.isPass === 1">审核通过</span>
            <span v-if="scope.row.isPass === -1">审核不通过</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="right">
          <template slot-scope="scope">
            <div class="operation_group">
              <el-button type="primary" @click="handleCheck(scope.row, 1)">通过</el-button>
              <el-button type="primary" @click="handleCheck(scope.row, -1)">不通过</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { request } from "../../API/request";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";


export default {
  name: '',
  components: { hearderInput, Pagination },

  data () {
    return {
      userList: [],
      queryInfo: {
        page: 1, //当前页数
        size: 5, //一页显示多个条数据
      },
      // 分页的总条数
      total: 0,
      principal: "", // 负责人
      principalList: [], // 负责人列表
      statusArr: [
        {
          value: 0,
          label: '待审核',
        },
        {
          value: 1,
          label: '通过',
        },
        {
          value: -1,
          label: '不通过',
        }
      ],
      selectStatus: ''
    }
  },
  created () {
    this.getUserList(this.selectStatus)
    request({
      url: "/user/getMember",
      method: "GET",
    }).then((res) => {
      console.log(res);
      if (res.code === 200) {
        this.principalList = res.data
      }
    })
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    handleSelectStatus () {
      console.log(this.selectStatus);
      this.queryInfo.page = 1
      this.getUserList(this.selectStatus)
    },
    getUserList (status) {
      let isPassStatus = 0
      if (status !== '') {
        isPassStatus = status
      }
      request({
        url: "/userInfo/pageDynamic",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          isPass: isPassStatus
        }
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.userList = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },
    handleCheck (item, status) {
      console.log(item);
      let that = this;
      let text = ''
      if (status === 1) {
        text = '审核通过'
      }
      if (status === -1) {
        text = '审核不通过'
      }
      that
        .$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          setTimeout(() => {
            request({
              url: "/userInfo/isPass",
              method: "POST",
              params: {
                id: item.id,
                pass: status
              }
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("修改审核状态成功！");
                that.getUserList(this.selectStatus)
              } else {
                this.$message.error(res.message);
              }
            });
          }, 10);
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getUserList(this.selectStatus)
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getUserList(this.selectStatus)
    }
  }
}
</script>

<style scoped lang=less>
.userCheck {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
  .header {
    .text {
      color: #606266;
    }
  }
}
.el-table {
  margin-top: 24px;
}
.operation_group {
  color: #155bd4;
  cursor: pointer;
}
.isTitle {
  font-size: var(--mainSize);
  text-align: center;
  margin-bottom: 12px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--subSize);
  .leftText {
    width: 100px;
  }
  .upPhotoList {
    position: relative;
    margin: 20px 20px 0 0;
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      .close_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
  .video {
    width: 180px;
    height: 300px;
    display: block;
  }
  .avatar-uploader {
    margin-top: 20px;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      border: 1px dashed #999999;
      font-size: 28px;
      color: #999999;
      width: 105px;
      height: 105px;
      line-height: 105px;
      text-align: center;
    }
  }
}
.avatar {
  width: 105px;
  height: 105px;
  display: block;
}
.btn {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .affirm {
    background: var(--pink);
    color: var(--white);
  }
}

</style>